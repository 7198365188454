#view1 {
  display: flex;
  flex-direction: row;
}

#rentImage {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  width: 45%;
}

#rentalFrom {
  width: 50%;
  padding: auto;
  margin: auto;
}

.rentProcess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imgHeight {
  height: 10vw;
}

#tractor {
  width: 11vw;
}

#requirement {
  width: 6vw;
}

#doorstep {
  width: 10vw;
}

.arrow {
  height: 2vw;
  width: 10vw;
  margin: 0 2vw 0 2vw;
}
.imgText {
  color: black;
  font-size: 1.3vw;
}
/* strong{
    color: black;
    font-size: 1.3vw;
} */
@media only screen and (max-width: 1000px) {
  #rentalHearder {
    font-size: large;
  }
  #rentalHearder2 {
    font-size: medium;
  }
  #rentalFrom {
    width: 100%;
    padding: auto;
    margin: auto;
  }
  #rentImage {
    display: none;
  }

  .imgHeight {
    height: 16vw;
  }
  #tractor {
    width: 17vw;
  }

  #requirement {
    width: 8vw;
  }

  #doorstep {
    width: 15vw;
  }

  .arrow {
    height: 2vw;
    width: 10vw;
    margin: 0 1vw 0 1vw;
  }
  .imgText {
    font-size: 3vw;
    color: black;
  }
}
