@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap");
html {
  font-size: "62.5%";
}

#root {
  font-family: "Roboto";
}
.body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.loading {
  opacity: 50%;
  cursor: not-allowed;
  pointer-events: none;
}
.spanc {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.sliderimg {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition-duration: 1s ease;
  transform: rotate3d(80);
  object-fit: cover;
}

.carousel-caption {
  width: 100%;
  height: 50%;
  left: 0 !important;
  font-weight: 800;
  font-size: 25px;
}

.carousel-caption h3 {
  text-align: center;
  margin-left: 30px;
  font-weight: 800;
  font-size: 45px;
}
.fontSize {
  font-size: "0.75rem";
}
.homepage {
  position: fixed;
}
.homepage.active {
  position: relative;
  margin-left: 25px;
  margin-right: 25px;
}

.footer {
  position: fixed;
  /* pointer-events: none; */
}
.footer.active {
  position: relative;
  /* pointer-events: all; */
}

.header {
  position: fixed;
  z-index: -2;
}
.header.active {
  position: relative;
  z-index: 0;
}

.aftersidenav {
  position: fixed;
}
.aftersidenav.active {
  position: relative;
}

.ImageSearch {
  object-fit: cover;
  width: 500px;
  background-color: white;
  position: absolute;
  height: 65vh;
  border-radius: 2%;
  /* padding: 5px 0px; */
  padding-left: 0px;
  margin-top: -72px;
  top: -100%;
  left: -100%;
  /* transition: all 0ms; */
  z-index: 2;
}

.ImageSearch.active {
  top: 1%;
  left: 45%;
}

.Sidebar {
  object-fit: cover;
  width: 280px;
  background-color: #bfe2ba;
  position: absolute;
  height: 96vh;
  /* padding: 5px 0px; */
  padding-left: 20px;
  margin-top: -72px;
  left: -100%;
  transition: all 0ms;
  z-index: 2;
}

.Sidebar.active {
  left: 0;
}

ul {
  padding: 0;
}

ul li {
  list-style-type: none;
  margin: 1px 0;
}

ul li a {
  color: black;
  /* font-weight: bold; */
  /* font-family: "Roboto"; */
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 7px 10px;
  border-radius: 6px;
}

ul li a:hover,
ul li a.active {
  background-color: black;
  color: white;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: none !important;
}

.MuiInput-underline:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: none !important;
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  /* flex-direction: row!important; */
  vertical-align: top;
}

.ml-img-card {
  max-width: 200px;
}
