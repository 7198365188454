.product-view-desktop{
    display: block;
}
.product-view-image{
    display:flex;
    flex-direction:column;
    width:30%;
}
.product-view-image-main{
    margin: 10px;
}
.product-view-image-child{
    display:flex;
    justify-content:center;
    align-items:center
}
.product-view-mobile{
    display: none;
}

@media only screen and (max-width: 768px){
    .product-view-mobile{
        display: block;
    }
    .product-view-desktop{
        display: none;
    }
    .product-view-image{
        width:auto;
    }
}