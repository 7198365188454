.my-carousel {
    margin: 20px 100px;
    
  }
  .inner-carousel{
    margin: 0px 20px; 
  }
  
  .my-card {
    margin-right: 5px;
    margin-left: 5px;
    width: 100%; /* To make sure cards occupy full width of the container */
    text-align: center; /* Center the content horizontally */
    position: relative; /* Add relative positioning for pseudo-elements */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  }

  .my-card:hover  {
    border: 2px solid black; /* Add a black border on hover */
  }
  .my-card.clicked {
    background-color: #f2f0f0; /* Gray background when clicked */
    color:black;
    border: 2px solid #666565; /* Red border when clicked */
  }

  /* SymptomsCard */
  
  .symptom-card {
    margin-bottom: 20px;
  }
  .white-text-with-rectangle {
    color: black;
    max-width: 35px;
    background-color: black;
    
    padding: 5px; /* Adjust padding as needed */
    border-radius: 5px; /* Adjust border-radius as needed */
    border:0.5px solid gray
  }

  /* status low high very good  */
  .status-text {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
  }
  
  .low-status {
    background-color:#B0D9B1;
    color: black;
  }
  
  .high-status {
    background-color: #79AC78;
    color: black;
  }
  
  .good-status {
    background-color: #307d37;
    color: black;
  }

  .side-margin{
    margin:0px 70px;
    background-color: #f4f4f4;
    color: black;
  }

  /* all 12 nutrent color css */
  /* Nitrogen */
.nitrogen-bg {
  background-color: #FFFFFF; /* White */
}

/* Phosphorus */
.phosphorus-bg-white {
  background-color: #9c8047; /* White */
}

.phosphorus-bg-red {
  background-color: #FF0000; /* Red */
}

/* Potassium */
.potassium-bg {
  background-color: #EDC7E4; /* Silver */
}

/* Sulfur */
.sulfur-bg {
  background-color: #FFFF00; /* Yellow */
}

/* Zinc */
.zinc-bg {
  background-color: #A2CFFE; /* Bluish-White */
}

/* Magnesium */
.magnesium-bg {
  background-color: #cb95d6; /* Silver */
}

/* Calcium */
.calcium-bg {
  background-color: rgba(161, 156, 156, 0.525); /* Silver */
}

/* Boron */
.boron-bg-brown {
  background-color: #a52a2a94; /* Brown */
}

/* Iron */
.iron-bg {
  background-color: #8a46467e; /* Silver */
}

/* Copper */
.copper-bg {
  background-color: #F7CCBF; /* Brownish-Red */
}

/* Manganese */
.manganese-bg {
  background-color: #eb9bd6d6; /* Silver */
}
.defult-color{
  color:white;
}

/* Hide the default Carousel indicators */
.carousel-indicators {
  display: none;
}

/* Style for the custom indicators */
.custom-carousel-indicators {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; /* Add margin as needed to create space below the indicators */
}

/* Style for the custom indicators */
.custom-carousel-indicator {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border: 0.2px solid #ffffff;
  border-radius: 50%;
  margin: 0 5px; /* Adjust the spacing between indicators */
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative; /* Enable relative positioning */
  top: -2px; /* Move the indicators 2 pixels up */
}

/* Hover effect */
.custom-carousel-indicator:hover {
  background-color: #b2afaf; /* Light black on hover */
}