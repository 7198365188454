.post{
    height:20rem;
    width:15rem;
    border-radius:10px;
    margin:20px;
    box-shadow:1px 2px 6px #808080;
    padding:20px;
}

.post-product-image{
    display:flex;
    justify-content:center;
    height:60%
}

.post-product-detail{
    height: 40%;
}

.post-name-brand{
    width:100%;
    height:38%;
    display:flex;
    flex-direction:column;
    justify-content:center;
}

.post-product-name{
    padding:0px 4px;
    font-size:16px;
    white-space:nowrap;
    overflow-x: hidden;
    text-overflow:ellipsis;
}

.post-product-brand{
    padding:0px 4px;
    font-size:12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.post-rating-price-cart-wishlist{
    width: 100%;
    display: flex;
    height: 70%;
}

.post-rating-price{
   width: 70%;
}

.post-rating{
    height: 30%;
}

.post-price{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-mrp{
    padding:0px 4px;
    font-size:12px
}

.post-offpercent{
    padding:0px 4px;
    font-size:14px;
    color:green
}

.post-selling-price{
    padding:0px 4px;
    font-size:18px
}

.post-cart-wishlist-icon{
    width:30%;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center
}

.post-cart-icon{
    height:1.8rem;
    width:1.8rem;
    aspect-ratio: 1;
    object-fit:contain;
    padding: 2%;
}

.post-wishlist-icon{
    height:1.8rem;
    width:1.8rem;
    aspect-ratio: 1;
    object-fit:contain;
    padding: 4%;
}


@media only screen and (max-width: 768px) {
    /* Styles to apply for mobile devices (widths up to 768px) */
    .post{
        height:15rem;
        width:11rem;
        border-radius:10px;
        margin:15px;
        box-shadow:1px 2px 6px #808080;
        padding:10px;
    }
    
    .post-product-image{
        display:flex;
        justify-content:center;
        height:60%
    }
    
    .post-product-detail{
        height: 40%;
    }
    
    .post-name-brand{
        width:100%;
        height:30%;
        display:flex;
        flex-direction:column;
        justify-content:center;
    }
    
    .post-product-name{
        padding:0px 2px;
        font-size:12px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
    
    .post-product-brand{
        padding:0px 2px;
        font-size:8px
    }
    
    .post-rating-price-cart-wishlist{
        width: 100%;
        display: flex;
        height: 70%;
    }
    
    .post-rating-price{
       width: 70%;
    }
    
    .post-rating{
        height: 30%;
    }
    
    .post-price{
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .post-mrp{
        padding:0px 2px;
        font-size:8px
    }
    
    .post-offpercent{
        padding:0px 2px;
        font-size:8px;
        color:green
    }
    
    .post-selling-price{
        padding:0px 2px;
        font-size:14px
    }
    
    .post-cart-wishlist-icon{
        width:30%;
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        align-items:center
    }
    
    .post-cart-icon{
        height:25px;
        width:25px;
        aspect-ratio: 1;
        object-fit:contain;
    }
    
    .post-wishlist-icon{
        height:20px;
        width:20px;
        aspect-ratio: 1;
        object-fit:contain;
    }
  }