@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap");

#root {
  font-family: "Roboto";
}
.body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.sliderimg {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition-duration: 1s ease;
  transform: rotate3d(80);
  object-fit: cover;
}

.carousel-caption {
  width: 100%;
  height: 50%;
  left: 0 !important;
  font-weight: 800;
  font-size: 25px;
}

.carousel-caption h3 {
  text-align: center;
  margin-left: 30px;
  font-weight: 800;
  font-size: 45px;
}

.homepage {
  position: fixed;
}
.homepage.active {
  position: relative;
  margin-left: 25px;
  margin-right: 25px;
}

.footer {
  position: fixed;
  /* pointer-events: none; */
}
.footer.active {
  position: relative;
  /* pointer-events: all; */
}

.header {
  position: fixed;
  z-index: -2;
}
.header.active {
  position: relative;
  z-index: 0;
}

.aftersidenav {
  position: fixed;
}
.aftersidenav.active {
  position: relative;
}

.ImageSearch {
  object-fit: cover;
  width: 500px;
  background-color: white;
  position: absolute;
  height: 85vh;
  border-radius: 2%;
  /* padding: 5px 0px; */
  padding-left: 0px;
  margin-top: -72px;
  top: -100%;
  left: -100%;
  /* transition: all 0ms; */
  z-index: 2;
}

.ImageSearch.active {
  top: 9%;
  left: 25%;
}

.Sidebar {
  object-fit: cover;
  width: 280px;
  background-color: #bfe2ba;
  position: absolute;
  height: 96vh;
  /* padding: 5px 0px; */
  padding-left: 20px;
  margin-top: -72px;
  left: -100%;
  transition: all 0ms;
  z-index: 2;
}

.Sidebar.active {
  left: 0;
}

ul {
  padding: 0;
}

ul li {
  list-style-type: none;
  margin: 1px 0;
}

ul li a {
  color: black;
  /* font-weight: bold; */
  /* font-family: "Roboto"; */
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 7px 10px;
  border-radius: 6px;
}

ul li a:hover,
ul li a.active {
  background-color: black;
  color: white;
}

/* .Button {
  color: "rgba(92,167,201,1)";
  
} */

$loader--width: 250px;
$loader-dot--size: 20px;
.containerpage {
  height: 100vh;
  width: 100vw;
  font-family: Helvetica;
}

.loader {
  height: $loader-dot--size;
  width: $loader--width;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  &--dot {
    animation-name: loader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    height: $loader-dot--size;
    width: $loader-dot--size;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: 2px solid white;

    &:first-child {
      background-color: #bfe2ba;
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      background-color: #acd7bc;
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      background-color: #99ccbe;
      animation-delay: 0.3s;
    }

    &:nth-child(4) {
      background-color: #86c1c0;
      animation-delay: 0.2s;
    }

    &:nth-child(5) {
      background-color: #73b6c2;
      animation-delay: 0.1s;
    }

    &:nth-child(6) {
      background-color: #60abc4;
      animation-delay: 0s;
    }
  }

  &--text {
    position: absolute;
    top: 200%;
    left: 0;
    right: 0;
    width: 4rem;
    margin: auto;

    &:after {
      content: "Loading";
      font-weight: bold;
      animation-name: loading-text;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX($loader--width - $loader-dot--size);
  }

  65% {
    transform: translateX($loader--width - $loader-dot--size);
  }

  95% {
    transform: translateX(0);
  }
}

@keyframes loading-text {
  0% {
    content: "Loading";
  }

  25% {
    content: "Loading.";
  }

  50% {
    content: "Loading..";
  }

  75% {
    content: "Loading...";
  }
}
