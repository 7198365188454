.carousel-control-prev-icon{
    background-image:url('../../assets/chevron-left.svg') !important;
    position: absolute;
    left: 0% !important;
  }
.carousel-control-next-icon{
    background-image:url('../../assets/chevron-right.svg') !important;
    position: absolute;
    right: 0% !important;
  }
.carousel-indicators li{
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}