.homepage-header{
    font-weight: Bold;
    font-size: 2rem;
    color: black;
    margin-right: 15px ;
}

.homepage-header-button{
    margin:0;
    border:none;
    /* background:#BFE2BA; */
    border-radius:25px;
    font-size:15px;
    padding: 10px;

}

@media only screen and (max-width: 768px){
    .homepage-header{
        font-size: 1rem;
    }
    .homepage-header-button{
        font-size:10px;
        padding: 5px;
    }
}