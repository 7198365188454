* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 710px;
    width: 100%;
    padding-left: 190px;
    line-height: 1.4;
}

.notfound .notfound-404 {
    position: absolute;
    left: 0;
    top: 10;
    width: 130px;
    height: 130px;
    background: #e01818;
    border-radius: 7px;
    -webkit-box-shadow: 0px 0px 0px 10px #e01818 inset, 0px 0px 0px 20px #fff inset;
    box-shadow: 0px 0px 0px 10px #e01818 inset, 0px 0px 0px 20px #fff inset;
}

.notfound .notfound-404 h1 {
    font-family: 'Roboto';
    color: #fff;
    font-size: 72px;
    margin: 0px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 60px;
    line-height: 60px;
}

.notfound h2 {
    font-family: 'Roboto';
    color: #292929;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-top: -17px;
}

.notfound h3 {
    font-family: 'Roboto';
    color: #8a8a8a;
    font-size: 85px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-top: 0;
}

.notfound p {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}

.notfound a {
    font-family: 'Roboto';
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: linear-gradient(#BFE2BA 0%, #5DA7CA 99.99%);
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #292929;
    font-weight: 700;
    -webkit-box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.notfound a:hover {
    color: #fff;
    background: linear-gradient(#BFE2BA 0%, #5DA7CA 99.99%);
}

@media only screen and (max-width: 480px) {
    .notfound {
        text-align: center;
    }
    .notfound .notfound-404 {
        position: relative;
        width: 40%;
        display: inline-block;
        margin-bottom: 15px;
    }
    .notfound {
        padding-left: 15px;
        padding-right: 15px;
    }
}