.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(52, 52, 52, 0.7);
    z-index: 999999;
  }

.loader-false {
    display: none;
  }
  
.dot-circle {
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    margin: 0 5px;
    animation: dotAnimation 2s linear infinite;
    position: absolute;
}

.dot-circle:nth-child(1) {
    animation-delay: 0s;
    background:  #5ca7c9;
}

.dot-circle:nth-child(2) {
    animation-delay: 0.2s;
    background: #64acc8;
}

.dot-circle:nth-child(3) {
    animation-delay: 0.4s;
    background: #6eb2c6;
}

.dot-circle:nth-child(4) {
    animation-delay: 0.6s;
    background: #77b7c5;
}

.dot-circle:nth-child(5) {
    animation-delay: 0.8s;
    background: #82bec3;
}

.dot-circle:nth-child(6) {
    animation-delay: 1s;
    background: #94c9c1;
}

.dot-circle:nth-child(7) {
    animation-delay: 1.2s;
    background: #9ccdc0;
}

 .dot-circle:nth-child(8) {
    animation-delay: 1.4s;
    background: #a5d3be;
}

.dot-circle:nth-child(9) {
    animation-delay: 1.6s;
    background: #add8bd;
}

.dot-circle:nth-child(10) {
    animation-delay: 1.8s;
    background: #bfe2ba;
}

@keyframes dotAnimation {
    0% {
      transform: rotate(0deg) translateX(80px) rotate(0deg);

    }
    100% {
      transform: rotate(360deg) translateX(80px) rotate(-360deg);
    }
  }
  