.cart{
    display: flex;
    flex-direction: row;
}

.cart-image{
    aspect-ratio:1;
    object-fit:cover;
    height:300px;
    width:1000px;
    margin:10px;
    border-radius:5px;
}

.order-summary{
    border:1px solid gray;
    width:100%;
    margin:10px;
    padding:10px;
    border-radius:5px;
}

@media only screen and (max-width: 768px){
    .cart{
        flex-direction: column;
    }
    .cart-image{
        display: none;
    }
    .order-summary{
        border:1px solid gray;
        width:auto;
        margin:10px;
        padding:10px;
        border-radius:5px;
    }
}