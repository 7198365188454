.product-filter{
    width:15%;
    min-height:75vh;
    border-right:1px solid gray;
}

.filter-header{
    display:flex;
    justify-content:space-between;
    border-bottom:1px solid black;
    height:50px;
    align-items:center
}

.filter-header-text{
    margin:10px;
    font-size:18px
}

.filter-header-button{
    margin:10px;
    padding:3px;
    font-size:18px;
    border:1px solid gray;
    border-radius:5px;
    cursor:pointer
}

.filter{
    margin:10px
}

.products-list{
    width:85%;
    min-height:75vh;
    display:flex;
    flex-direction:column;
    padding:10px
}

.product{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
}
.product-sort{
    border:1px solid gray;
    height:fit-content;
    width:fit-content;
    margin:10px;
    border-radius:5px;
    padding:8px
}

.sort-select{
    border:none;
    outline: none;
    min-width:12rem
}

.sort-section{
    border:1px solid gray;
    display:flex;
    justify-content:space-between 
}
.filter-mobile-button{
    display: none;
}

@media only screen and (max-width: 768px){
    .product-filter{
        display: none;
    }

    .product{
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around
    }
    .products-list{
        width:100%;
        padding: 2px;
    }
    .product{
        display:flex;
        flex-wrap:wrap;
        justify-content: space-around;
    }
    .product-sort{
        margin:5px;
        padding:5px
    }
    .sort-select{
        min-width:5rem
    }
    .sort-text{
       font-size: 12px;
    }
    .sort-option{
        font-size: 12px;
    }
    .filter-mobile-button{
        border: 1px solid gray;
        border-radius: 5px;
        margin: 5px;
        padding: 2px 15px;
        display: flex;
        align-items: center;
    }
}