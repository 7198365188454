.header-2-ul{
    width: 100%;
    height: 70px; /* Assuming '70' was intended as '70px' */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #BFE2BA;
    margin: 0;
}

.header-2-li{
  align-items: center;
  display: flex;
  cursor: pointer;
}

.header-2-text{
  border-radius: 5px;
  padding: 10px;
  color: black;
  background: none;
}

.header-2-text:hover{
    color: #fff;
    background: black;
}

@media only screen and (max-width: 768px){
    .header-2{
      display: none;
    }
    .header-2-ul{
        width: 100%;
        height: 50px; /* Assuming '70' was intended as '70px' */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        background: #BFE2BA;
        margin: 0;
    }
    .header-2-text{
        border-radius: 2px;
        padding: 5px;
        color: black;
        background: none;
        font-size: 5px;
      }
}